
import {
  mdiPlusCircleOutline,
  mdiFormatListBulletedType,
  mdiInformationOutline,
  mdiApps,
} from "@mdi/js";
import Vue from "vue";
import { tourDocToData } from "@/convertData";

export default Vue.extend({
  data() {
    return {
      mdiPlusCircleOutline,
      mdiFormatListBulletedType,
      mdiApps,
      mdiInformationOutline,
      tours: [] as object[],
    };
  },
  async mounted() {
    await this.$fire.remoteConfig.fetchAndActivate().catch((err) => {
      this.$sentry.captureException(err);
    });
    const hots = JSON.parse(
      this.$fire.remoteConfig.getValue("hot_tours").asString()
    );
    if (Array.isArray(hots)) {
      // if (this.$vuetify.breakpoint.mdAndUp) {
      hots.forEach((tourId, index) => {
        this.$fire.firestore
          .doc(`tours/${tourId}`)
          .get()
          .then((tourDoc) => {
            if (!tourDoc.exists) {
              return;
            }
            this.$set(this.tours, index, tourDocToData(tourDoc));
          })
          .catch((err) => {
            this.$sentry.captureException(err);
          });
      });
      // }
    }
  },
  methods: {
    webpSrc(src: string) {
      return src.replace(/\/tours\/thumb_/, "/tours/thumbp_");
    },
  },
});
