
import Vue from "vue";
import type { MetaInfo } from "vue-meta";
import { tourDocToData } from "@/convertData";

export default Vue.extend({
  layout: "app-bar",
  data() {
    const tours: Peeeps.Data.Tour[] = [];
    return {
      tours,
      loading: true,
      fetching: false,
      lastVisible: null as null | firebase.default.firestore.DocumentData,
    };
  },
  head(): MetaInfo {
    const title = this.$t("meta.top-title") + " | peeeps";
    return {
      title,
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content: title,
        },
        {
          hid: "description",
          name: "description",
          content: this.$t("meta.top-description") as string,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.$t("meta.top-description") as string,
        },
      ],
    };
  },
  mounted() {
    const limit = 16;
    this.$fire.firestore
      .collection(`tours`)
      .where("draft", "==", false)
      .where("suspended", "==", false)
      .limit(limit)
      .get()
      .then((toursCol) => {
        this.lastVisible = toursCol.docs[toursCol.docs.length - 1];
        this.tours = this.shuffleList(
          toursCol.docs.map((tourDoc) => tourDocToData(tourDoc))
        );
      })
      .catch((err) => {
        this.$sentry.captureException(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    shuffleList(tours: Peeeps.Data.Tour[]) {
      for (let i = tours.length - 1; i >= 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        const tmpStorage = tours[i];
        tours[i] = tours[rand];
        tours[rand] = tmpStorage;
      }
      return tours;
    },
  },
});
